import API from 'o365.modules.data.api.ts';
import Crc32 from 'o365.lib.crc32.js';
import type { UploadOptions } from 'o365.modules.FileUpload.utils.ts';

import ChunkUploader from 'o365.modules.ChunkUploader.ts';
//import CryptoJS from 'o365.lib.md5.min.js';

 interface IUploads {
     //   dataObject: DataObject;
    xhr?:XMLHttpRequest
    currentChunkIndex: number;
    running?: boolean;
    fileCRC?: number;
    fileMDP?: string;
    action?: string;
    fileRef?: string;
    chunkCrcs?:Array<number>
}
 interface IUploadOptions {
     //   dataObject: DataObject;
    file:File
    fileCRC?: number;
    fileRef?: number;
    uploadRef?: string;
    data?:Array<Object>;
    responseData?: any,
    onProgress?:Function
}

interface IFileUploadOptions {
    url?: string,
    useChunks?: boolean,
    viewName?: string,
    chunkUrl?: string
}


export default class FileUpload {

    private _xhrs: Set<XMLHttpRequest> = new Set();
    private _chunkUploader: ChunkUploader;
    private _uploads: Map<File,IUploads> = new Map();
    private _chunkUploads: Map<File,ChunkUploader> = new Map();
    private _uploadQueue: UploadQueue = new UploadQueue();
    

    /* private _onError;
     private _onProgress;
     private _onComplete;*/
    private _url: string = '/nt/upload';
    private _chunkUrl: string = '/nt/api/file/chunkupload';
    private _useChunks: boolean = false;
    private _primKey: string|null = null;
    private _chunkSize: number = 40 * 1024 * 1024;//8*1024*1024;//8mb


    private _viewName: string|undefined;


    useCustom:boolean = true;

    get url() {
        if (this._primKey) {
            return this._url + "/" + this._primKey;
        }
        return this._url;
    }

    set url(pValue){
        this._url = pValue;
        this._chunkUrl = pValue;
    }
    set useChunks(pValue:boolean){
        this._useChunks = pValue;
    }

    get options(){
        return {
            url:this.url,

            chunkUrl:this._chunkUrl,
            viewName:this._viewName
        }
    }

    

    constructor(pOptions: IFileUploadOptions) {

        if(pOptions["url"]) this._url = pOptions["url"];
        if(pOptions.chunkUrl) this._chunkUrl = pOptions.chunkUrl;
      
        if(pOptions["useChunks"]) this._useChunks = pOptions["useChunks"];

        this._viewName = pOptions["viewName"];
 

        


    }
    private _uploadFile(pUrl: string, pFormData: any, pOnProgress: Function|null = null, pHeaders: Map<string, any>|null = null) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            this._xhrs.add(xhr);
            xhr.open('POST', pUrl);
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('X-NT-API', 'true');
            xhr.onload = (e) => {
                if (xhr.status === 200) {
                    this._xhrs.delete(xhr);
                    try {
                        resolve(this._onCompleteEvent(e));
                    } catch (ex) {
                        reject(new Error('Could not parse response'));
                    }
                } else {// if(e.constructor !== ProgressEvent){
                    this._xhrs.delete(xhr);
                    reject(this._onErrorEvent(e));
                }
            };


            xhr.onabort = () => {

                this._xhrs.delete(xhr);

                reject("Upload was cancelled by user");
            };
            xhr.onerror = (e) => {
                this._xhrs.delete(xhr);
                reject(this._onErrorEvent(e));
            };
            xhr.ontimeout = (e) => {
                console.warn("timeout", e);
                this._xhrs.delete(xhr);
                reject(this._onErrorEvent(e));
            };

            if (pOnProgress)
                xhr.upload.addEventListener("progress", (e) => {
                    pOnProgress.call(this, e);
                }, false);

            if (pHeaders) {
                pHeaders.forEach((val, key) => {
                    xhr.setRequestHeader(key, val);
                })
            }

            xhr.send(pFormData);
        })


    }

    abort() {
        //this._chunkUploader.abort(true);
        this._chunkUploads.forEach(ch=>{
            ch.abort(true);
        })
        this._xhrs.forEach(xh => {
            xh.abort();
        });

    }
    cancel() {
        this.abort();

    }

    async upload(pOptions:IUploadOptions) {
        const start = Date.now();

        this._chunkUploads.clear();
        if(this.useCustom && this._useChunks){
            await this._uploadQueue.getTicket()

            const vUploader = new ChunkUploader(this.options);
            this._chunkUploads.set(pOptions.file, vUploader);

            const vRes = await vUploader.upload(pOptions);
            this._uploadQueue.decrementActiveUploads();
            this._uploadQueue.processTicketQueue();
            const delta = Date.now() - start;

            console.log(pOptions.file.name,"Duration: ",Math.floor(delta / 1000),Math.floor(pOptions.file.size / 1000));
            return vRes;
        }

        if (this._useChunks) {
 
          
            this._calculateCrc32(pOptions.file).then((crc: any) => {
                const vUpload = this._uploads.get(pOptions.file);
                if(vUpload) vUpload.fileCRC = crc;
            })

            
            const vRes = await this._uploadChunks(pOptions);
            const delta = Date.now() - start;

           console.log(pOptions.file.name,"Duration: ",Math.floor(delta / 1000),Math.floor(pOptions.file.size / 1000));
            return vRes;
        }

        if (pOptions.uploadRef) {
            this._primKey = pOptions.uploadRef;
        } else {
            this._primKey = null;
        }

        const requestBody = new FormData();
        requestBody.append('File', pOptions.file);
        if (pOptions.data) {
            Object.keys(pOptions.data).forEach((key) => {
                requestBody.append(key, pOptions.data[key]);
            })
        };

        return this._uploadFile(this.url, requestBody, pOptions['onProgress']);
    }

    async runQueue(){

    }

    async _getUploadProgress(pOptions:IUploadOptions) {
        return await await API.requestPost("nt/api/file/uploadprogress", JSON.stringify({
            FileName: pOptions.file.name,
            FileSize: pOptions.file.size,
            ViewName: this._viewName,
            FileCRC: pOptions.fileCRC,
            UploadRef: pOptions.uploadRef
        }));
    }



    async _uploadChunks(pOptions:IUploadOptions, pChunkIndex: number = 0) {
        //How to know that this the last chunk?
        const vHeaders = new Map<string, any>();
        let vUpload = this._uploads.get(pOptions.file);
        
        if(!vUpload){
             this._uploads.set(pOptions.file,{
                currentChunkIndex:pChunkIndex
            });
            vUpload = this._uploads.get(pOptions.file);
          
        }

        if(vUpload && vUpload.fileCRC){
            pOptions.fileCRC = vUpload.fileCRC;
        }
        let vStart = pChunkIndex * this._chunkSize;
        if (vStart > pOptions.file.size) return Promise.resolve({ FileRef: pOptions.fileRef, ResponseData: pOptions.responseData });

        const vEnd = Math.min(vStart + this._chunkSize, pOptions.file.size);
        

        vHeaders.set('Custom-Content-Range', `bytes ${vStart}-${vEnd - 1}/${pOptions.file.size}`);
        if(pOptions.fileCRC){
            vHeaders.set('X-File-CRC',pOptions.fileCRC);
        }

        let vUrl = this._chunkUrl;
        if (pOptions.uploadRef) {
            vUrl += "/" + pOptions.uploadRef;
        }
        const vFormData = new FormData();
        vFormData.append("File", this._slice(pOptions.file, vStart, vEnd), pOptions.file.name);


        const vUploadRef:any = await this._uploadFile(vUrl, vFormData, (e:ProgressEvent) => {
            if (pOptions.onProgress) {
                pOptions.onProgress.call(this, {
                    loaded: vStart + e.loaded
                })
            }
        }, vHeaders);

    

        if(vUploadRef.hasOwnProperty("action")){
            if(vUploadRef['action'] == "ReuseFileRef"){
                this._uploads.delete(pOptions.file);
                if (pOptions.onProgress) {
                    pOptions.onProgress.call(this, {
                        loaded: pOptions.file.size
                    })
                }
                return Promise.resolve({ FileRef: vUploadRef['fileRef'] })
            }
        }

        if(vUploadRef.hasOwnProperty("fileRef")){
            pOptions['fileRef'] = vUploadRef['fileRef'];
        }
        pOptions.responseData = vUploadRef;

        pOptions['uploadRef'] = vUploadRef['uploadRef'];

        return this._uploadChunks(pOptions, pChunkIndex + 1);

    }

    private _slice(file: File, start: number, end: number) {
        const slice = file['mozSlice'] ? file['mozSlice'] :
            file['webkitSlice'] ? file['webkitSlice'] :
                file.slice ? file.slice : 'noop';
        return slice.bind(file)(start, end);
    }


    private _onCompleteEvent(e: ProgressEvent) {
        return JSON.parse(e.target['response']);
    }
 
        private _calculateCrc32(pFile:File){
            const vThat = this;
            return new Promise(resolve=>{
                var reader = new FileReader();
                var crc32 = new Crc32(),
    
                    crc32Update:any,
                    //checksum = [], not sure why is need, maybe if to use async
                    chunker = function() {                            
                        let i=0,
                            start = i,
                            stop = Math.min(start + vThat._chunkSize, pFile.size),
                            fileSlicer;
                            var checker = function() {
    
                                i++;
                                start = i*vThat._chunkSize;
    
                                if(start > pFile.size){
                                    crc32Update.finalize();
                                    resolve(crc32Update << 0)
                                    return;
                                }
                                stop = Math.min(start + vThat._chunkSize, pFile.size);
                                readBlock(start, stop, pFile);
                            };
    
                            var readBlock = function(start,stop, pFile) {
                    
                                fileSlicer = pFile.slice(start, stop);
    
                                reader.onloadend = function(){
                                    crc32Update = crc32.update(this.result);
                                    const vUpload = vThat._uploads.get(pFile);
                
                                    if(!vUpload.chunkCrcs) vUpload.chunkCrcs = [];
                                    vUpload.chunkCrcs.push(crc32Update.crc << 0);
                                    checker();
                                }
                                reader.readAsArrayBuffer(fileSlicer);
                            }; 
    
                            readBlock(start, stop, pFile);
                    };
                    chunker();
            });
        }
    
    private _onErrorEvent(e:ProgressEvent) {
        if (this._isJsonString(e.target['response'])) {
            return JSON.parse(e.target['response']).error;
        } else if (e.target['response']) {
            return e.target['response'];
        } else {
            return "Unspecified error";

        }

    }



    private _isJsonString(str:string) {
        if (str === null || str === "") {
            return false;
        }
        try {

            JSON.parse(str);
        } catch (e) {
            return false;
        }
        if (str.length === 0) {
            return false;
        } else {
            return true;
        }
    }
}

export class ProgressHandler{
    private items: Map<File, Progress>;
    private _currentProgress: number|null = null;
    private _message: string|null = null;
    private _start:number = 0;
    
    speed:number = 0;

    inProgress: boolean = false;
    
    uploaded: number = 0;
    total: number = 0;
    error: string | null = "";
    
    set message(pMessage){
        this._message = pMessage;
        this.fireOnProgress();
    }
    get message(){
        return this._message;
    }


    onProgress:Function|undefined = undefined;
    get currentProgress(){
        return this._currentProgress;
    }

    get totals(){
        return {
            files:this.items,
            total:this.total,
            uploaded:this.uploaded,
            progress:this.uploaded !== 0?Math.min(Math.round((this.uploaded/this.total)*100),100):0,
            completed:this.uploaded===this.total,
            error:this.error,
            message:this.message,
            speed:this.speed
        }
    }

    set currenProgress(pVal:number){
        this._currentProgress = pVal;
    }
    get progress(){
        return this.items
    }
    constructor(){
        this.items = new Map();
    }

    start(pOptions: UploadOptions){
        this._start = new Date().getTime();
        this.reset();
        this.inProgress = true;
       
        for (let i = 0; i < pOptions.files!.length; i++) {
            let vFile = pOptions.files![i];
            this.items.set(vFile as File, new Progress(vFile));
            this.total += vFile.size;

        }
        this.onProgress = pOptions.onProgress;

    }
    updateError(pFile: File, pMessage: string){
        const vItem = this.items.get(pFile);

        if (!vItem) {
            return;
        }

        vItem.error = pMessage;
        this.error = pMessage;
        this.message = null;
        this.inProgress = false;
        this.fireOnProgress();
    }

    updateProgress(pFile: File, pParams: any) {

        const vItem = this.items.get(pFile);

        if (!vItem) {
            return;
        }
        var lastBytes = this.uploaded;

        vItem.uploaded = Math.min(pParams.loaded, vItem.total);
        this.uploaded = 0;

        this.items.forEach((val) => {
            this.uploaded += val.uploaded;

        })
        var vUploaded = (this.uploaded-lastBytes);
        
        const elapsed = (new Date().getTime() - this._start) / 1000;
        this.speed = elapsed ? (vUploaded / elapsed) : 0;
        this.fireOnProgress();
    }

    fireOnProgress(){
        if(this.onProgress) this.onProgress.call(this,this.totals);
    }
    cancel(){
        this.reset();
    }

    completeUpload(){
        this.reset();
        this.inProgress = false;
    }

    reset(){
       
        this.items = new Map();
        

        this.total = 0;
        this.uploaded = 0;
        this.error = null;
   
   
    }
}

class Progress{
    private _uploaded:number = 0;
    name:string;
    path:string;
    total:number;
    error:string|null = null;
    
    set uploaded(pValue){
        if(this.total < pValue){
            this._uploaded = this.total;
        }else{
            this._uploaded = pValue;
        }
    }

    get uploaded(){
        return this._uploaded;
    }
   


    get completed(){
        return this.uploaded >= this.total;
    }
    get id(){
        if(this.path){
            return this.path + "/"+this.name;
        }

        return this.name;
    }

    constructor(pFile: any){
        this.total = pFile.size;
        this.name = pFile.name;
        this.path = pFile.path;
    }
}

class UploadQueue {
    private maxConcurrentUploads: number = 6;
    private activeUploads: number = 0;
    private ticketQueue: (() => void)[] = [];

    public decrementActiveUploads() {
        this.activeUploads--;
    }

    public getTicket(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.ticketQueue.push(resolve); 
            this.processTicketQueue();
        });
    }

    public processTicketQueue() {
        if (this.ticketQueue.length === 0 || this.activeUploads >= this.maxConcurrentUploads) {
            return;
        }

        this.activeUploads++;
        const nextTicketResolver = this.ticketQueue.shift(); 

        if (nextTicketResolver) {
            nextTicketResolver();
        }
    }
}
